import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiClient,
  ApiErrorResponse,
  ApiResponse,
  ENV,
  IEnvironment,
  IListParams,
  IListResponse,
  ISupplierApiResponse,
  List,
  SupplierApi,
  SupplierApiPayload,
  SupplierApiUpdatePayload,
} from '@shabic/models';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SupplierApiService extends ApiClient {
  override readonly url = '/supplier-api';

  constructor(private http: HttpClient, @Inject(ENV) env: IEnvironment) {
    super(env);
  }

  getAllForCurrentUser(params: IListParams) {
    return this.http
      .get<IListResponse<ISupplierApiResponse[]>>(this.apiModelUrl, { params })
      .pipe(
        map(
          response =>
            new List(response, content =>
              content.map(el => new SupplierApi(el))
            )
        ),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }


  create(payload: SupplierApiPayload) {
    return this.http.post<ISupplierApiResponse>(this.apiModelUrl, payload).pipe(
      map(response => new ApiResponse(new SupplierApi(response))),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  update(payload: SupplierApiUpdatePayload,id:number) {
    return this.http
      .put<ISupplierApiResponse>(this.getEndpoint(`/${id}`), payload)
      .pipe(
        map(response => new ApiResponse(new SupplierApi(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }



  delete(id: number) {
    return this.http.delete<unknown>(this.getEndpoint(`/${id}`)).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }


}
