<shabic-dialog #dialog>
  <shabic-headline align="center" variant="h3">
    {{ 'dialog.add-supplier-api' | translate }}
  </shabic-headline>
  <form (ngSubmit)="onSubmit(dialog)" [formGroup]="formGroup" class="px-4">

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label>
        <span class="normal-case text-sm">
          {{ 'common.environment' | translate }}
        </span>
      </shabic-form-field-label>
      <div class="flex gap-5.5 mt-3">
        <shabic-radio-control>
          <input
            class="peer"
            formControlName="environment"
            id="prod"
            shabicInput
            type="radio"
            value="PROD"
          />
          <shabic-form-field-label forAttribute="environment">
            <span class="normal-case text-sm">
              {{ 'common.prod' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>
        <shabic-radio-control>
          <input
            class="peer"
            formControlName="environment"
            id="test"
            shabicInput
            type="radio"
            value="TEST"
          />
          <shabic-form-field-label forAttribute="environment">
            <span class="normal-case text-sm">
              {{ 'common.test' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>
      </div>
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="apiType">
        {{ 'common.api-type' | translate }}
      </shabic-form-field-label>
      <select
        autocomplete="off"
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="apiType"
        id="apiType"
        shabicInput
      >
        <option value="INVENTORY_INQUIRY">Inventory Inquiry API</option>
        <option value="BURSHASE_ORDER">Purchase Order API</option>
        <option value="QUOTATION_REQUEST">quotation request</option>
      </select>
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="apiUrl">
        {{ 'common.api-url' | translate }}
      </shabic-form-field-label>
      <input
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="apiUrl"
        id="apiUrl"
        shabicInput
        type="text"
      />
    </shabic-form-field>

    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="apiSwagger">
        {{ 'common.api-swagger' | translate }}
      </shabic-form-field-label>
      <input
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="apiSwagger"
        id="apiSwagger"
        shabicInput
        type="text"
      />

    </shabic-form-field>


    <shabic-form-field class="text-primary mt-5.5">
      <shabic-form-field-label>
        <span class="normal-case text-sm">
          {{ 'common.auth-type' | translate }}
        </span>
      </shabic-form-field-label>

      <div class="flex gap-5.5 mt-3">
        <shabic-radio-control>
          <input
            class="peer"
            formControlName="authType"
            id="basic"
            shabicInput
            type="radio"
            value="BASIC"
          />

          <shabic-form-field-label forAttribute="authType">
            <span class="normal-case text-sm">
              {{ 'common.basic-auth' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>

        <shabic-radio-control>
          <input
            class="peer"
            formControlName="authType"
            id="jwt"
            shabicInput
            type="radio"
            value="JWT"
          />

          <shabic-form-field-label forAttribute="authType">
            <span class="normal-case text-sm">
              {{ 'common.jwt-auth' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>
        <shabic-radio-control>
          <input
            class="peer"
            formControlName="authType"
            id="apiKey"
            shabicInput
            type="radio"
            value="API_KEY"
          />

          <shabic-form-field-label forAttribute="no">
            <span class="normal-case text-sm">
              {{ 'common.api-key-auth' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>
        <shabic-radio-control>
          <input
            class="peer"
            formControlName="authType"
            id="basicAndApiKey"
            shabicInput
            type="radio"
            value="BASIC_AND_API_KEY"
          />

          <shabic-form-field-label forAttribute="no">
            <span class="normal-case text-sm">
              {{ 'common.basic-and-api-key-auth' | translate }}
            </span>
          </shabic-form-field-label>
        </shabic-radio-control>
      </div>
    </shabic-form-field>
    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="userId">
        {{ 'common.user-id' | translate }}
      </shabic-form-field-label>
      <input
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="userId"
        id="userId"
        shabicInput
        type="text"
      />

    </shabic-form-field>
    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="password">
        {{ 'common.password' | translate }}
      </shabic-form-field-label>
      <input
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="password"
        id="password"
        shabicInput
        type="text"
      />

    </shabic-form-field>
    <shabic-form-field class="text-primary mt-4">
      <shabic-form-field-label forAttribute="apiKey">
        {{ 'common.api-key' | translate }}
      </shabic-form-field-label>
      <input
        class="ltr:pr-6 rtl:pl-6 border border-grey-300"
        formControlName="apiKey"
        id="apiKeyAuth"
        shabicInput
        type="text"
      />

    </shabic-form-field>
    <div class="flex flex-col my-6">
      <button
        [disabled]="(loading$ | async) || formGroup.invalid"
        class="gap-1"
        shabicButton="primary"
        type="submit"
      >
        {{ ('button.test') | translate }}
        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
      
    </div>
    <div class="flex flex-col my-6">
      <button
        [disabled]="(loading$ | async) || formGroup.invalid"
        class="gap-1"
        shabicButton="primary"
        type="submit"
      >
        {{ ('button.save') | translate }}
        <shabic-spinner *ngIf="loading$ | async"></shabic-spinner>
      </button>
      
    </div>
  </form>
</shabic-dialog>
