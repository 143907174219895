import { Injectable } from '@angular/core';
import { ListProduct, Product } from '@shabic/models';

@Injectable({
  providedIn: 'root'
})
export class ProductDataService {
  private products: ListProduct[] = []; 
  private quantities :number[]=[];
  private productQuantityMap: Map<Product, number> = new Map();

  updateProductQuantityMap(): void {
    this.productQuantityMap.clear();
    this.products.forEach((product, index) => {
      this.productQuantityMap.set(product, this.quantities[index] || 0);
    });
  }

  getProductQuantityMap(): Map<Product, number> {
    return this.productQuantityMap;
  }
  setProducts(products: ListProduct[]): void {
    this.products = products;
  }

  getProducts(): ListProduct[] {
    return this.products;
  }

  setQuantities(quantities: number[]): void {
    this.quantities = quantities;
  }
   getQuantities(): number[] {  
    return this.quantities; }
    
}