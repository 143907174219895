import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getCompanyExample } from '@shabic/data';
import {
  ApiErrorResponse,
  ApiResponse,
  Company,
  CompanyPayload,
  ICompanyResponse,
  ENV,
  IEnvironment,
  CompanyValue,
  isErrorResponse,
  Option,
  List,
  IListResponse,
  CompanyDocument,
  ICompanyDocumentResponse,
  ICompanyAPIService,
  ApiClient,
  Supplier,
  ICompanyParams,
  CompanyDocumentStatus,
  FilterOption,
  IListParams,
  IPromotionResponse,
  CompanyPromotion,
} from '@shabic/models';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { FileService } from './file.service';
import { MediaService } from './media.service';
import { groupBy, keys, prop, zipObj } from 'ramda';
import { PromotionService } from './promotion.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends ApiClient implements ICompanyAPIService {
  private _initValue: Option[] = [];

  private _types = new BehaviorSubject(this._initValue);
  private _classifications = new BehaviorSubject(this._initValue);
  private _activities = new BehaviorSubject(this._initValue);
  private _posTypes = new BehaviorSubject(this._initValue);

  override readonly url: string = '/companies';

  constructor(
    private http: HttpClient,
    @Inject(ENV) env: IEnvironment,
    private translateService: TranslateService,
    private fileService: FileService,
    private authService: AuthService,
    private mediaService: MediaService,
    private promotionService: PromotionService
  ) {
    super(env);
  }

  types$: Observable<Option[]> = this._types.asObservable();
  activities$: Observable<Option[]> = this._activities.asObservable();
  classifications$: Observable<Option[]> = this._classifications.asObservable();
  posTypes$: Observable<Option[]> = this._posTypes.asObservable();

  getMyCompany(): Observable<ApiResponse<Company | ApiErrorResponse>> {
    return this.http.get<ICompanyResponse>(this.getEndpoint('/my')).pipe(
      map(response => new ApiResponse(this.parseCompanyResponse(response))),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }
  getActiveSuppliers(): Observable<number> {
    return this.http.get<number>(this.getEndpoint('/activeSupplier')).pipe(
      map(response => response),
      catchError(response =>
        of(0) 
      )
    );
  }


  getCompany(id: number): Observable<ApiResponse<Company | ApiErrorResponse>> {
    return this.http.get<ICompanyResponse>(this.getEndpoint('/' + id)).pipe(
      map(response => new ApiResponse(this.parseCompanyResponse(response))),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  getCompaniesByIds(ids: (number | string)[]) {
    const params = new HttpParams({
      fromObject: { ids },
    });

    return this.http
      .get<ICompanyResponse[]>(this.getEndpoint('/by-ids'), { params })
      .pipe(
        map(response => {
          return new ApiResponse(<IListResponse<Company[]>>{
            content: response.map(el => this.parseCompanyResponse(el)),
            totalElements: response.length,
            totalPages: 1,
            number: 0,
            size: response.length,
            last: true,
          });
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  register(
    company: CompanyPayload
  ): Observable<ApiResponse<Company | ApiErrorResponse>> {
    return this.uploadPicture(company).pipe(
      switchMap(response => {
        const mediaId = response.payload[0]?.id;

        return this.http
          .post<ICompanyResponse>(this.getEndpoint('/register'), {
            ...company,
            mediaId: mediaId || null,
          })
          .pipe(
            map(response => new ApiResponse(new Company(response))),

            switchMap(response =>
              forkJoin({
                response: of(response),
                token: this.authService.upgradeToken(),
              })
            ),

            map(({ response }) => response),

            catchError(response =>
              of(new ApiResponse(new ApiErrorResponse(response)))
            )
          );
      })
    );
  }

  updateCompanyValues(): Observable<boolean> {
    return this.getValues().pipe(
      map(response => {
        if (isErrorResponse(response)) {
          return false;
        }

        const values = response.payload as Record<CompanyValue, string[]>;

        this._types.next(
          values.types.map(item => new Option(this.translateService, item))
        );
        this._classifications.next(
          values.classifications.map(
            item => new Option(this.translateService, item)
          )
        );
        this._activities.next(
          values.businessActivities.map(
            item => new Option(this.translateService, item)
          )
        );
        this._posTypes.next(
          values.posTypes.map(
            item => new Option(this.translateService, item)
          )
        );

        return true;
      })
    );
  }

  getOptionValue(target: string | null, type: CompanyValue): unknown | null {
    let collection: Option[];

    switch (type) {
      case 'types':
        collection = this._types.value;
        break;
      case 'classifications':
        collection = this._classifications.value;
        break;
      case 'businessActivities':
        collection = this._activities.value;
        break;
      case 'posTypes':
        collection = this._posTypes.value;
        break;
      default:
        collection = [];
    }

    return collection.find(option => option.label === target)?.value;
  }

  getFilterTypes() {
    return this.http
      .get<Record<string, string[]>>(this.getEndpoint('/values'))
      .pipe(
        map(response => {
          const filters: Record<string, FilterOption[]> = {};

          Object.keys(response).forEach(key => {
            filters[key] = response[key].map(
              el =>
                new FilterOption({
                  id: el,
                  name: el,
                })
            );
          });

          return new ApiResponse(filters);
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getSupplierList(
    query: ICompanyParams
  ): Observable<
    ApiResponse<List<Supplier, ICompanyResponse[]> | ApiErrorResponse>
  > {
    const params = new HttpParams({
      fromObject: { ...query },
    });

    return this.http
      .get<IListResponse<ICompanyResponse[]>>(this.apiModelUrl, {
        params,
      })
      .pipe(
        map(
          response =>
            new ApiResponse(
              new List(response, content =>
                content.map(company => new Supplier(new Company(company)))
              )
            )
        ),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  activateCompany(
    company: Company
  ): Observable<ApiResponse<Company | ApiErrorResponse>> {
    const params = new HttpParams({
      fromObject: {
        activateUser: true,
      },
    });

    return this.http
      .get<ICompanyResponse>(this.getEndpoint(`/activate/${company.id}`), {
        params,
      })
      .pipe(
        map(response => new ApiResponse(new Company(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  updateCompany() {
    return of(new ApiResponse(getCompanyExample()));
  }

  updateCompanyDocuments(
    files: File[]
  ): Observable<ApiResponse<CompanyDocument[] | ApiErrorResponse>> {
    const requests = files.map(el => {
      const body = new FormData();
      body.append('file', el);
      body.append('description', '');

      return this.http.put<ICompanyDocumentResponse>(
        this.getApiEndpoint('/company/documents'),
        body
      );
    });

    return forkJoin(requests).pipe(
      map(
        responses =>
          new ApiResponse(
            responses.map(response => new CompanyDocument(response))
          )
      ),
      catchError(this.cathError)
    );
  }

  deleteCompanyDocument(
    document: CompanyDocument
  ): Observable<ApiResponse<unknown | ApiErrorResponse>> {
    return this.http
      .delete(this.getApiEndpoint(`/company/documents/${document.id}`))
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(this.cathError)
      );
  }

  getCompanyDocument(document: CompanyDocument, download: boolean) {
    return this.fileService.downloadFile(
      this.getApiEndpoint(`/company/documents/${document.id}`),
      document.name,
      download
    );
  }

  markAsRead(document: CompanyDocument) {
    const body: { status: CompanyDocumentStatus } = {
      status: 'READ',
    };
    return this.http
      .put<ICompanyDocumentResponse>(
        this.getApiEndpoint(`/company/documents/${document.id}/status`),
        body
      )
      .pipe(
        map(response => new ApiResponse(new CompanyDocument(response))),
        catchError(this.cathError)
      );
  }

  getCompanyDocuments(
    company: Company
  ): Observable<ApiResponse<CompanyDocument[] | ApiErrorResponse>> {
    const params = new HttpParams({
      fromObject: { companyId: company.id },
    });

    return this.http
      .get<IListResponse<ICompanyDocumentResponse[]>>(
        this.getApiEndpoint('/company/documents'),
        { params }
      )
      .pipe(
        map(
          response =>
            new ApiResponse(response.content.map(el => new CompanyDocument(el)))
        ),
        catchError(this.cathError)
      );
  }

  getValues(): Observable<
    ApiResponse<Record<CompanyValue, string[]> | ApiErrorResponse>
  > {
    return this.http
      .get<Record<CompanyValue, string[]>>(this.getEndpoint('/values'))
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  rejectCompany(
    company: Company,
    rejectReason: string
  ): Observable<ApiResponse<Company | ApiErrorResponse>> {
    const body = { rejectReason };

    return this.http
      .post<ICompanyResponse>(this.getEndpoint(`/reject/${company.id}`), body)
      .pipe(
        map(response => new ApiResponse(this.parseCompanyResponse(response))),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  updateLogo(company: CompanyPayload) {
    return this.uploadPicture(company).pipe(
      switchMap(response => {
        const mediaId = response.payload[0]?.id;

        return this.http
          .put<ICompanyResponse>(this.getEndpoint('/logo'), {
            mediaId: mediaId || null,
          })
          .pipe(
            map(response => new ApiResponse(new Company(response))),

            switchMap(response =>
              forkJoin({
                response: of(response),
                token: this.authService.upgradeToken(),
              })
            ),

            map(({ response }) => response),

            catchError(response =>
              of(new ApiResponse(new ApiErrorResponse(response)))
            )
          );
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  getPromotions(params: IListParams) {
    const query = new HttpParams({
      fromObject: params || {},
    });

    return this.promotionService.getPromotions(params).pipe(
      switchMap(response => {
        if (response instanceof List) {
          const companies = keys(
            groupBy(el => prop('companyId', el).toString(), response.content)
          );

          return forkJoin({
            promotions: of(response),
            companies: this.getCompaniesByIds(companies),
            error: of(null),
          });
        } else {
          return forkJoin({
            promotions: of(response),
            companies: of(response),
            error: of(response),
          });
        }
      }),

      map(response => {
        const { promotions, companies, error } = response;

        if (error) {
          return error;
        } else if (promotions instanceof List && !isErrorResponse(companies)) {
          const entity = groupBy(
            el => prop('companyId', el).toString(),
            promotions.content
          );

          return new List(companies.payload, content =>
            content.map(el => {
              return new CompanyPromotion({
                company: el,
                promotions: entity[el.id],
              });
            })
          );
        } else {
          return List.initValue();
        }
      }),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  private parseCompanyResponse(payload: ICompanyResponse): Company {
    const company: ICompanyResponse = {
      ...payload,
      type: payload.type ? this.translateService.instant(payload.type) : null,
      classification: payload.classification
        ? this.translateService.instant(payload.classification)
        : null,
      businessActivity: payload.businessActivity
        ? this.translateService.instant(payload.businessActivity)
        : null,
    };

    return new Company(company);
  }

  private uploadPicture(payload: CompanyPayload) {
    if (payload.mediaId?.length) {
      return this.mediaService.upload(
        this.getApiEndpoint(''),
        payload.mediaId,
        'COMPANY'
      );
    } else {
      return of(new ApiResponse([]));
    }
  }
}
