import { Icon } from '@shabic/icon';
import { PromotionType } from '@shabic/models';

export interface INavItem {
  label: string;
  route: string | string[];
  onlyForAuthorized?: boolean;
  onlyForSupplier?: boolean;
  icon?: Icon;
  onClick?: (event: MouseEvent | TouchEvent) => void;
  childRoute?: boolean;
  mobileHidden?: boolean;
  hasBanner?: boolean;
  promotionType?: PromotionType;
}

export const NOT_AUTH_NAV: INavItem[] = [
  {
    label: 'page.home',
    route: '/search',
    icon: 'icon-home',
    mobileHidden: true,
  },
  {
    label: 'navigation.products',
    route: '/catalog',
    icon: 'icon-search',
    mobileHidden: true,
    hasBanner: true,
    promotionType: 'PRODUCTS_CATALOG',
  },
  {
    label: 'navigation.suppliers',
    route: '/supplier',
    onlyForAuthorized: true,
    hasBanner: true,
    promotionType: 'SUPPLIERS',
    icon: 'icon-users',
  },
  {
    label: 'navigation.orders',
    route: '/orders',
    onlyForAuthorized: true,
    mobileHidden: true,
    icon: 'icon-basket',
  },
  {
    label: 'navigation.quote-requests',
    route: '/my-quotas',
    onlyForAuthorized: true,
    icon: 'icon-document',
  },
];

export const ADMIN_NAV: INavItem[] = [
  { label: 'page.user-management', route: '/' },
  { label: 'navigation.products', route: '/catalog' },
  { label: 'page.reports', route: '/reports' },
  { label: 'navigation.products-category', route: '/products-classification' },
  { label: 'page.my-banks', route: '/my-banks' },
  { label: 'page.marketplace', route: '/marketplace' },
];

export const PROFILE_NAV: INavItem[] = [
  {
    label: 'navigation.profile',
    route: '/profile',
    icon: 'icon-person',
    mobileHidden: true,
  },
  {
    label: 'profile.change-password',
    route: '/profile/change-password',
    icon: 'icon-password',
  },
  {
    label: 'navigation.bank-information',
    route: '/profile/bank-account',
    onlyForAuthorized: true,
    icon: 'icon-bulk',
  },
  {
    label: 'navigation.supplier.api',
    route: '/profile/supplier-api',
    onlyForAuthorized: true,
    icon: 'icon-document',
  },
  {
    label: 'navigation.company-info',
    route: '/profile/company-info',
    icon: 'icon-file',
  },
  {
    label: 'navigation.my-products',
    route: '/my-products',
    icon: 'icon-bag',
    onlyForAuthorized: true,
    onlyForSupplier: true,
    mobileHidden: true,
  },
  {
    label: 'navigation.api-user',
    route: '/profile/api-user',
    icon: 'icon-person',
    onlyForAuthorized: true,
  },
  {
    label: 'navigation.marketing',
    route: '/profile/marketing',
    icon: 'icon-person',
    onlyForAuthorized: true,
    onlyForSupplier: true,
  },
];

export const ADMIN_PROFILE_NAV: INavItem[] = [
  { label: 'navigation.profile', route: '/profile' },
];

export const FOOTER_NAV: INavItem[] = [
  { label: 'navigation.about-shabic', route: '/' },
  { label: 'navigation.terms', route: '/' },
  { label: 'navigation.contact-us', route: '/' },
  { label: 'navigation.help', route: '/' },
];

export const CART_NAV: INavItem[] = [
  { label: 'page.cart', route: '/cart/pre-order', icon: 'icon-basket' },
  {
    label: 'navigation.checkout',
    route: '/cart/checkout',
    icon: 'icon-basket',
    mobileHidden: true,
  },
  {
    label: 'page.cart',
    route: '/cart/order-created',
    icon: 'icon-basket',
    mobileHidden: true,
  },
];

export const ADD_PRODUCT_NAV: INavItem[] = [
  {
    label: 'page.add-product',
    route: '/add-product',
    icon: 'icon-add',
    mobileHidden: true,
  },
];
