import { DIALOG_DATA } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SUCCESS } from '@shabic/constants';
import { FormComponent } from '@shabic/form-control';
import {
 
  CreateSupplierApiDialogData,
  SupplierApiPayload,  
  Option,
  SupplierApi,
  EditSupplierApiDialogData,
  SupplierApiUpdatePayload,
} from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';
import { EventBrokerService } from '@shabic/event-broker';

@Component({
  selector: 'shabic-supplier-api-dialog',
  templateUrl: './supplier-api-dialog.component.html',
  styleUrls: ['./supplier-api-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierApiDialogComponent
  extends FormComponent<SupplierApi>
  implements OnInit
{
  formGroup = new FormGroup({
    apiType: new FormControl('', [Validators.required]),
    apiUrl: new FormControl('', [Validators.required]),
    apiSwagger: new FormControl('', [Validators.required]),
    authType: new FormControl('', [Validators.required]),
    userId: new FormControl('', []),
    password: new FormControl('', []),
    apiKey: new FormControl('', []),
    environment: new FormControl('', [Validators.required]),
  });


  constructor(
    @Inject(DIALOG_DATA)
    public data: CreateSupplierApiDialogData | EditSupplierApiDialogData,
    private translateService: TranslateService,
    protected override cd: ChangeDetectorRef,

  ) {
    super();
  }

  get isEditMode() {
    return this.data instanceof EditSupplierApiDialogData;
  }

  ngOnInit(): void {
    this.formGroup.get('authType')?.valueChanges.subscribe((authType) => {
      if (authType === 'BASIC') {
        this.formGroup.get('userId')?.setValidators([Validators.required]);
        this.formGroup.get('password')?.setValidators([Validators.required]);
        this.formGroup.get('apiKey')?.clearValidators();
        this.formGroup.get('apiKey')?.disable();
        this.formGroup.get('userId')?.enable();
        this.formGroup.get('password')?.enable();

      }
       else if (authType === 'API_KEY') {
        this.formGroup.get('userId')?.clearValidators();   
        this.formGroup.get('password')?.clearValidators();
        this.formGroup.get('apiKey')?.setValidators([Validators.required]);
        this.formGroup.get('apiKey')?.enable();
        this.formGroup.get('userId')?.disable();
        this.formGroup.get('password')?.disable();
      }
      else if (authType === 'JWT') {
        this.formGroup.get('userId')?.clearValidators();
        this.formGroup.get('password')?.clearValidators();
        this.formGroup.get('apiKey')?.clearValidators();
        this.formGroup.get('apiKey')?.disable();
        this.formGroup.get('userId')?.disable();
        this.formGroup.get('password')?.disable();
      }
      else if (authType === 'BASIC_AND_API_KEY') {
        this.formGroup.get('userId')?.setValidators([Validators.required]);
        this.formGroup.get('password')?.setValidators([Validators.required]);
        this.formGroup.get('apiKey')?.setValidators([Validators.required]);
        this.formGroup.get('apiKey')?.enable();
        this.formGroup.get('userId')?.enable();
        this.formGroup.get('password')?.enable();
      }
      
  
    });

    if (this.data instanceof EditSupplierApiDialogData) {
      const { api } = this.data;

      this.formGroup.patchValue({
        apiType: api.apiType,
        apiUrl: api.endPointURL,
        environment: api.environment,
        apiSwagger: api.swaggerURL,
        authType: api.authenticationType,
        userId: api.userName,
        password: api.password,
        apiKey: api.apiKey,
      });
    }
  }

  onSubmit(dialog: DialogComponent) {
    if (this.formGroup.invalid) {
      return;
    }
    console.log(this.formGroup.value);

    if (this.data instanceof CreateSupplierApiDialogData) {
      const body: SupplierApiPayload = {
        environment: this.formGroup.value.environment as string,
        apiType: this.formGroup.value.apiType as string,
        endPointURL: this.formGroup.value.apiUrl as string,
        swaggerURL: this.formGroup.value.apiSwagger as string,
        authenticationType: this.formGroup.value.authType as string,
        userName: this.formGroup.value.userId as string,
        password: this.formGroup.value.password as string,
        apiKey: this.formGroup.value.apiKey as string,
      };
      this.submitForm(this.formGroup, this.data.onConnect(body)).subscribe(
        (status) => {
          console.log(status);

          if (status === SUCCESS) {
            this.data.onSuccess();
            dialog.close();
          }
        }
      );
    }

    if (this.data instanceof EditSupplierApiDialogData) {
      const body: SupplierApiUpdatePayload = {
        environment: this.formGroup.value.environment as string,
        apiType: this.formGroup.value.apiType as string,
        endPointURL: this.formGroup.value.apiUrl as string,
        swaggerURL: this.formGroup.value.apiSwagger as string,
        authenticationType: this.formGroup.value.authType as string,
        userName: this.formGroup.value.userId as string,
        password: this.formGroup.value.password as string,
        apiKey: this.formGroup.value.apiKey as string,
      };
      this.submitForm(this.formGroup, this.data.onEdit(body)).subscribe(
        (status) => {
          console.log(status);

          if (status === SUCCESS) {
            this.data.onSuccess();
            dialog.close();
          }
        }
      );
    }
  }
}
