<shabic-dialog #dialog>
  <shabic-headline align="center" variant="h3">
    {{ 'dialog.choose-quantity' | translate }}
  </shabic-headline>

  <hr class="text-grey-300 mt-5.5 max-w-[515px] mx-auto" />

  <form [formGroup]="formGroup" class="max-w-[515px] mx-auto">
    <div formArrayName="quantities">
      <ng-container *ngFor="let product of products; let i = index">
        <div class="flex gap-5 my-4" [formGroupName]="i">
          <img
            shabicImage
            [src]="product.images[0]"
            alt=""
            width="180"
            height="180"
            class="w-[180px] h-[180px]"
          />

          <div>
            <shabic-headline variant="h3">
              {{ product.name.get('en') }}
            </shabic-headline>

            <ul class="mt-3 mb-4">
              <li class="flex gap-1">
                <span class="text-xs font-medium text-grey uppercase">
                  {{ 'common.category' | translate }}
                </span>
                <span class="text-xs font-medium uppercase text-primary">
                  {{ product.category?.name?.get('en') }}
                </span>
              </li>

              <li class="flex gap-1 mt-0.5">
                <span class="text-xs font-medium text-grey uppercase">
                  {{ 'common.brand' | translate }}
                </span>
                <span class="text-xs font-medium uppercase text-primary">
                  {{ product.brand }}
                </span>
              </li>
            </ul>

            <div class="flex items-end mt-4">
              <shabic-form-field>
                <shabic-form-field-label>
                  <span class="text-sm text-primary normal-case">
                    {{ 'common.quantity' | translate }}
                  </span>
                </shabic-form-field-label>

                <input
                  formControlName="quantity"
                  type="number"
                  shabicInput
                  name=""
                  min="1"
                  class="w-10 max-h-[60px] ltr:rounded-r-none rtl:rounded-l-none border border-grey-400"
                />
              </shabic-form-field>
            </div>
          </div>
        </div>

        <hr class="text-grey-300" />
      </ng-container>
    </div>

    <p class="text-center">
      <button
        type="button"
        shabicButton="primary"
        class="mt-5 mb-6"
        (click)="confirm(dialog)"
      >
        {{ 'common.confirm' | translate }}
      </button>
    </p>
  </form>
</shabic-dialog>
