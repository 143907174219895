<shabic-animate>
  <div class="flex items-center justify-between mb-4">
    <shabic-headline variant="h1">
      {{ 'page.supplier-api' | translate }}
    </shabic-headline>


  </div>

  <ng-container *ngIf="list$ | async as list">
    <ng-container *ngIf="loading$ | async; else apisTemplate">
      <div class="text-grey flex justify-center py-5">
        <shabic-spinner size="xl"></shabic-spinner>
      </div>
    </ng-container>
    <ng-template #apisTemplate>
      <div class="grid gap-5.5">
        <div *ngFor="let api of list.content">
          <div class="grid gap-3 bg-grey-350 rounded-3xl p-3 desktop:max-w-[560px]">
            <div class="flex items-center gap-3">


              <div class="inline-flex items-center gap-1">
                <shabic-headline variant="h3">
                  {{ api.apiType }}
                </shabic-headline>
                <br>
                <hr class="w-full my-2 border-t border-gray-300">
                <shabic-headline variant="h5">
                  {{ api.environment }}
                </shabic-headline>
              </div>
            </div>

            <div class="bg-white p-4 rounded-3xl">


              <shabic-property-with-label label="common.api-url">
                <span class="truncate">
                  {{ api.endPointURL }}
                </span>
              </shabic-property-with-label>
              <br>
                <shabic-property-with-label label="common.api-swagger" class="mb-10">
                <span class="truncate">
                  {{ api.swaggerURL }}
                </span>
              </shabic-property-with-label>
              <br>
              <shabic-property-with-label label="common.auth-type">
                <span class="truncate">
                  {{ api.authenticationType }}
                </span>
              </shabic-property-with-label>

              <br>
              <shabic-property-with-label label="common.user-id">
                <span class="truncate">
                  {{ api.userName }}
                </span>
              </shabic-property-with-label>
              <br>
              <shabic-property-with-label label="common.password">
                <span class="truncate">
                  {{ api.password }}
                </span>
              </shabic-property-with-label>
              <br>
              <shabic-property-with-label label="common.api-key">
                <span class="truncate">
                  {{ api.apiKey }}
                </span>
              </shabic-property-with-label>

            </div>

        
          </div>

          <div class="flex gap-2 desktop:gap-3 mt-3 desktop:mt-4 flex-wrap">
            <button type="button" shabicButton="primary" (click)="onEdit(api)"
            class="w-full desktop:w-auto">
              {{ 'button.edit' | translate }}

            </button>

            <button type="button" shabicButton="danger" (click)="onDelete(api)" class="w-full desktop:w-auto">
              {{ 'button.delete' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <div class="flex gap-3 mt-5.5">
      <button type="button" shabicButton="primary" (click)="onAddApi()">
        {{ 'button.add-supplier-api' | translate }}
      </button>
    </div>
  </ng-container>
</shabic-animate>