import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { FORM_CONFIG } from '@shabic/form-control';
import {
  Address,
  CompanyDocument,
  CompanyModel,
  Option,
  PreviewDialogData,
} from '@shabic/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'shabic-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: FORM_CONFIG, useValue: { markRequiredFields: true } }],
})
export class CompanyInfoComponent implements OnInit {
  private _companyLoading = new BehaviorSubject<boolean>(true);
  private companyModel: CompanyModel;

  types$: Observable<Option[]>;
  activities$: Observable<Option[]>;
  classifications$: Observable<Option[]>;
  posTypes$: Observable<Option[]>;
  loading$: Observable<boolean>;
  documents$?: Observable<CompanyDocument[]>;
  companyLoading$ = this._companyLoading.asObservable();
  hasCompany?: boolean;

  constructor(
    private companyService: CompanyService,
    private translateService: TranslateService,
    private eventBrokerService: EventBrokerService,
    protected cd: ChangeDetectorRef
  ) {
    this.companyModel = new CompanyModel(
      this.companyService,
      this.translateService
    );
    this.loading$ = this.companyModel.loading$;
    this.types$ = this.companyModel.types$;
    this.posTypes$ = this.companyModel.posTypes$;

    this.activities$ = this.companyModel.activities$;
    this.classifications$ = this.companyModel.classifications$;
  }

  get today(): Date {
    return new Date();
  }

  get companyForm() {
    return this.companyModel.form.get('company') as FormGroup;
  }

  get documentsForm() {
    return this.companyModel.form.get('documents') as FormGroup;
  }

  get fileControls(): FormControl[] {
    return (this.documentsForm as unknown as FormArray)
      .controls as FormControl[];
  }

  get address() {
    return this.companyModel.address;
  }

  ngOnInit(): void {
    this.initForm();
  }

  onSubmit(): void {
    if (this.companyModel.isConfirmed) {
      this.companyModel.updateCompany().subscribe();
    } else {
      this.companyModel.register().subscribe(() => {
        if (!this.hasCompany) {
          this.initForm();
        }
      });
    }
  }

  uploadDocuments() {
    this.companyModel.uploadDocuments()?.subscribe(() => {
      this.companyModel.updateDocuments();
      (this.documentsForm as unknown as FormArray).clear();
      this.cd.markForCheck();
    });
  }

  addControl() {
    this.companyModel.pushDocumentControl();
  }

  onDownload(document: CompanyDocument): void {
    this.companyModel.getDocument(document, true).subscribe();
  }

  onPreview(document: CompanyDocument) {
    this.companyModel.getDocument(document, false).subscribe(response => {
      if (response.payload instanceof Blob) {
        this.eventBrokerService.emit(
          new AppEvent<PreviewDialogData>(EAppEvent.OpenPreviewDialog, {
            document,
            file: response.payload,
          })
        );
      }
    });
  }

  onDelete(document: CompanyDocument) {
    this.companyModel.deleteDocument(document).subscribe();
  }

  hasPreviewButton(document: CompanyDocument) {
    return document.type.includes('image') || document.type.includes('pdf');
  }

  hasDeleteButton() {
    return !this.companyModel.isConfirmed;
  }

  patchValue(key: string, value: unknown) {
    this.companyForm.get(key)?.patchValue(value);
  }

  onUpdateAddress(event: Address) {
    this.companyModel.updateAddress(event);
  }

  private initForm() {
    this.companyModel.init().subscribe(model => {
      this._companyLoading.next(false);
      model.updateDocuments();
      this.documents$ = model.documents$;
      this.hasCompany = model.hasCompany;
    });
  }
}
