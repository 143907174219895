import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule as MatDialogModule } from '@angular/cdk/dialog';
import { DialogComponent, ConfirmDialogComponent } from './components';
import { EventBrokerModule } from '@shabic/event-broker';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '@shabic/ui';
import { IconModule } from '@shabic/icon';
import { RequestProductsDialogComponent } from './components/request-products-dialog/request-products-dialog.component';
import { FormControlModule } from '@shabic/form-control';
import { ReturnOrderDialogComponent } from './components/return-order-dialog/return-order-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BankBalanceDialogComponent } from './components/bank-balance-dialog/bank-balance-dialog.component';
import { RequestAccountStatementDialogComponent } from './components/request-account-statement-dialog/request-account-statement-dialog.component';
import { AccountStatementDialogComponent } from './components/account-statement-dialog/account-statement-dialog.component';
import { FormsModule, PaginationModule } from '@shabic/shared';
import { DocumentsDialogComponent } from './components/documents-dialog/documents-dialog.component';
import { ProductFormDialogComponent } from './components/product-form-dialog/product-form-dialog.component';
import { ProductModule } from '@shabic/product';
import { PreviewDialogComponent } from './components/preview-dialog/preview-dialog.component';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { LoginRequireDialogComponent } from './components/login-require-dialog/login-require-dialog.component';
import { RouterModule } from '@angular/router';
import { RejectCompanyDialogComponent } from './components/reject-company-dialog/reject-company-dialog.component';
import { CompanyProfileDialogComponent } from './components/company-profile-dialog/company-profile-dialog.component';
import { UtilsModule } from '@shabic/utils';
import { NgxMaskModule } from 'ngx-mask';
import { BlockUserDialogComponent } from './components/block-user-dialog/block-user-dialog.component';
import { BankAccountDialogComponent } from './components/bank-account-dialog/bank-account-dialog.component';
import { SupplierApiDialogComponent } from './components/supplier-api-dialog/supplier-api-dialog.component';
import { BankDialogComponent } from './components/bank-dialog/bank-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CategoryDialogComponent } from './components/category-dialog/category-dialog.component';
import { NavigationDialogComponent } from './components/navigation-dialog/navigation-dialog.component';
import { DrawerDialogComponent } from './components/drawer-dialog/drawer-dialog.component';
import { DeactivateProductDialogComponent } from './components/deactivate-product-dialog/deactivate-product-dialog.component';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { LayoutModule } from '@angular/cdk/layout';
import { GalleryDialogComponent } from './components/gallery-dialog/gallery-dialog.component';
import { ForgotPasswordDialogComponent } from './components/forgot-password-dialog/forgot-password-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    EventBrokerModule,
    TranslateModule,
    UiModule,
    IconModule,
    FormControlModule,
    ReactiveFormsModule,
    PaginationModule,
    ProductModule,
    RouterModule,
    UtilsModule,
    NgxMaskModule,
    MatDatepickerModule,
    LayoutModule,
    IconModule,
    FormsModule,
  ],
  declarations: [
    DialogComponent,
    ConfirmDialogComponent,
    RequestProductsDialogComponent,
    ReturnOrderDialogComponent,
    ReturnOrderDialogComponent,
    BankBalanceDialogComponent,
    RequestAccountStatementDialogComponent,
    AccountStatementDialogComponent,
    DocumentsDialogComponent,
    ProductFormDialogComponent,
    PreviewDialogComponent,
    LoginDialogComponent,
    LoginRequireDialogComponent,
    RejectCompanyDialogComponent,
    CompanyProfileDialogComponent,
    BlockUserDialogComponent,
    BankAccountDialogComponent,
    SupplierApiDialogComponent,
    BankDialogComponent,
    CategoryDialogComponent,
    NavigationDialogComponent,
    DrawerDialogComponent,
    DeactivateProductDialogComponent,
    FilterDialogComponent,
    GalleryDialogComponent,
    ForgotPasswordDialogComponent,
  ],
})
export class DialogModule {}
