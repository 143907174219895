import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ADMIN_APP } from '@shabic/constants';
import { CategoriesService, ProductService } from '@shabic/core';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import {
  AppType,
  ENV,
  Category,
  IEnvironment,
  ProductSearchContent,
  ListProduct,
  ListComponent,
  IListParams,
  RequestProductsDialogData,
  productStatuses,
  ProductStatus,
  ITag,
  FilterOption,
  SortOption,
  ApiErrorResponse,
  isErrorResponse,
  FilterDialogData,
  FilterPayload,
} from '@shabic/models';
import { SearchComponent } from '@shabic/search';
import { BehaviorSubject, combineLatest, interval, of } from 'rxjs';
import { switchMap, filter, map, takeUntil, take } from 'rxjs/operators';
import { IProductStatus } from '../../models';

interface ISearchParams extends IListParams {
  search?: string;
  categoryIds?: number[];
  sort?: string;
  statuses?: ProductStatus[];
  skipMyProducts?: boolean;
  attributeFilter?: Record<string, string[]>;
}

@Component({
  selector: 'shabic-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent
  extends ListComponent<ListProduct, ProductSearchContent>
  implements OnInit, OnDestroy
{
  private _tags = new BehaviorSubject<ITag[]>([]);
  private _categories = new BehaviorSubject<Category[]>([]);
  private _filtersChanged = new BehaviorSubject<boolean>(false);
  private _suppliers = new BehaviorSubject<null | FilterOption[]>(null);
  private _brands = new BehaviorSubject<null | FilterOption[]>(null);
  private _countries = new BehaviorSubject<null | FilterOption[]>(null);
  private _colors = new BehaviorSubject<null | FilterOption[]>(null);
  private _hasClearButton = new BehaviorSubject<boolean>(false);

  @Input() name = '';
  @ViewChild(SearchComponent) searchComponent!: SearchComponent;

  @Output() request = new EventEmitter<ListProduct>();

  tags$ = this._tags.asObservable();
  categories$ = this._categories.asObservable();
  filterChanged$ = this._filtersChanged.asObservable();
  hasClearButton$ = this._hasClearButton.asObservable();

  readonly app: AppType;

  selectedCategory?: Category;

  statuses: IProductStatus[] = productStatuses.map(status => ({
    name: `status.${status}`,
    active: status === 'ACTIVE',
    status,
  }));

  readonly sortOptions: SortOption<string>[] = [
    { key: 'lastModifiedDate,asc', label: 'common.newest' },
    { key: 'lastModifiedDate,desc', label: 'common.oldest' },
    { key: 'priceFirst,desc', label: 'common.high_price' },
    { key: 'priceFirst,asc', label: 'common.low_price' },
  ];

  suppliers$ = this._suppliers.asObservable();
  brands$ = this._brands.asObservable();
  countries$ = this._countries.asObservable();
  colors$ = this._colors.asObservable();

  filterForm = new FormGroup<{ [x: string]: AbstractControl<string[]> }>({});

  get hasProductStatusFilter(): boolean {
    return this.app === ADMIN_APP;
  }

  get isFormNotEmpty() {
    return Object.keys(this.filterForm.value).some(
      key => this.filterForm.get(key)?.value.length
    );
  }

  constructor(
    @Inject(ENV) env: IEnvironment,
    private eventBrokerService: EventBrokerService,
    private productService: ProductService,
    private categoriesService: CategoriesService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.app = env.app;
  }

  ngOnInit() {
    this.initFilters();

    this.initList(
      switchMap(params => {
        const { attributeFilter = {}, ...rest } = params as ISearchParams;
        const attributes = { ...attributeFilter };

        delete attributes['COMPANY'];

        return this.productService
          .getProducts({
            ...rest,
            attributeFilter: attributes,
          })
          .pipe(map(response => response.payload));
      }),
      'barCodeUuid'
    );

    this.params$
      .pipe(
        filter(params => params !== null),
        takeUntil(this.destroy$)
      )
      .subscribe(params => this.updateCategories(params as ISearchParams));

    this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(params => {
      const query = params['query'];

      if (query) {
        try {
          const decodedParams = this.decodeQuery(query);

          const newParams: ISearchParams = {
            ...decodedParams,
            page: 'page' in decodedParams ? decodedParams['page'] : 0,
            skipMyProducts: true,
            statuses: decodedParams['statuses'] || ['ACTIVE'],
          };

          this.statuses = this.statuses.map(el => {
            return {
              ...el,
              active: el.status === newParams.statuses?.[0],
            };
          });

          if (!this.isInit && Array.isArray(newParams['page'])) {
            newParams['page'] = Math.max(...newParams['page']);
          }

          this.setParams(newParams);
          this._filtersChanged.next(false);
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setParams({
          page: 0,
          skipMyProducts: true,
          sort: 'lastModifiedDate,asc',
          statuses: ['ACTIVE'],
        });
        this._filtersChanged.next(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  onChangeParam(params: ISearchParams): void {
    this.router.navigate(['catalog'], {
      queryParams: {
        query: this.encodeQuery(params),
      },
    });
  }

  onRequestSelectedProducts(): void {
    this.eventBrokerService.emit(
      new AppEvent<RequestProductsDialogData>(
        EAppEvent.OpenRequestProductsDialog,
        {
          products:
            this.getContent()?.filter(
              product => this.listForm.value[product.barCodeUuid]
            ) || [],
          onConfirm: () => of(),
        }
      )
    );
  }

  onChangeCategory(category: Category): void {
    this.changeParam('categoryIds', [category.id]);
  }

  onChangeTag(tag: ITag): void {
    this.changeParam('categoryIds', [tag.key as number]);
  }

  onRequest(product: ListProduct): void {
    this.request.emit(product);
  }

  onStatusChange(status: ProductStatus) {
    this.changeParam('statuses', [status]);
  }

  onFilterSearch(data?: Record<string, any>) {
    const value = data || this.filterForm.value;
    for (const prop in value) {
      if (!value[prop]?.length) {
        delete value[prop];
      }
    }

    if ('COMPANY' in value) {
      this.changeParam('companyIds', value['COMPANY']);
    } else {
      this.changeParam('companyIds', []);
    }

    this.changeParams({
      attributeFilter: value,
      companyIds: value['COMPANY'],
    });
  }

  onSort(option: SortOption<string>) {
    this.changeParam('sort', option.key);
  }

  onShowFilter() {
    const filters: FilterPayload[] = [
      {
        options: this.categories$,
        label: 'common.category',
      },
      {
        options: this.suppliers$,
        key: 'COMPANY',
        label: 'common.supplier',
        value: '',
      },
      {
        options: this.brands$,
        key: 'BRAND',
        label: 'common.brand',
        value: '',
      },
      {
        options: this.countries$,
        key: 'COUNTRY',
        label: 'common.country-of-origin',
        value: '',
      },
      {
        options: this.colors$,
        key: 'COLOR',
        label: 'common.color',
        value: '',
        colorBox: true,
      },
    ];

    this.eventBrokerService.emit(
      new AppEvent<FilterDialogData>(
        EAppEvent.OpenFilterDialog,
        new FilterDialogData(filters, this.categories$, result => {
          if (this.isFiltersChanged(result)) {
            const { categoryIds, ...params } = result;

            this.changeParams({
              categoryIds,
              attributeFilter: params,
            });
            this.onFilterSearch(params);
          }
        })
      )
    );
  }

  onLoadMore() {
    this.loadMore();
  }

  onReset() {
    setTimeout(() => {
      this.onFilterSearch();
      this.searchComponent.closeSearchPopover();
    }, 20);
  }

  private updateCategories(params: ISearchParams): void {
    combineLatest({
      list: this.categoriesService.updateCategories(),
      count: this.productService.getProductCountByCategory(),
    }).subscribe(({ list, count }) => {
      const categories = Array.isArray(list.payload) ? list.payload : [];
      const countMap =
        count.payload instanceof ApiErrorResponse ? {} : count.payload;

      categories.forEach(category => {
        category.count = countMap[category.id] || 0;
      });

      if (params.categoryIds) {
        const targetCategoryId = params.categoryIds[0];
        const targetCategory = categories.find(
          category => category.id == targetCategoryId
        );
        this.selectedCategory = targetCategory;
        if (targetCategory) {
          targetCategory.active = true;
        }

        const tags: ITag[] = categories
          .filter(category => category.parentId == targetCategoryId)
          .map(category => ({
            key: category.id,
            name: category.name,
          }));
        this._tags.next(tags);

        if (targetCategory?.parentId) {
          const highLevelCategories = categories.filter(
            category => category.parentId == targetCategory.parentId
          );

          this._categories.next(highLevelCategories);
        } else {
          const highLevelCategories = categories.filter(
            category => !category.parentId
          );

          this._categories.next(highLevelCategories);
        }
      } else {
        const highLevelCategories = categories.filter(
          category => !category.parentId
        );

        this._categories.next(highLevelCategories);
        this._tags.next([]);
      }
    });
  }

  private initFilters() {
    this.productService
      .getFilterTypes()
      .pipe(
        switchMap(response => {
          if (isErrorResponse(response)) {
            return of(response);
          } else {
            const params = this.getParams()?.['attributeFilter'] || {};

            return this.productService.getFilterTypeValues(
              response.payload,
              params
            );
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(response => {
        if (!isErrorResponse(response)) {
          Object.keys(response.payload).forEach(el => {
            switch (el) {
              case 'BRAND':
                this._brands.next(response.payload['BRAND']);
                break;
              case 'COLOR':
                this._colors.next(response.payload['COLOR']);
                break;
              case 'COUNTRY':
                this._countries.next(response.payload['COUNTRY']);
                break;
              case 'COMPANY':
                this._suppliers.next(response.payload['COMPANY']);
            }
          });
        }
      });

    this.filterForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        interval(100)
          .pipe(take(1))
          .subscribe(() => {
            const changed = this.isFiltersChanged(value);
            !changed && this.searchComponent.closeSearchPopover();
            this.checkIfFilterFormEmpty();
            this._filtersChanged.next(changed);
          });
      });
  }

  private isFiltersChanged(newValue: any): boolean {
    const params: IListParams =
      this.getParams()?.['attributeFilter'] || ({} as IListParams);
    let changed = false;

    Object.keys(newValue).forEach((key: string) => {
      if (key in params) {
        const isEqual =
          newValue[key]?.length === params[key].length &&
          newValue[key]?.every((el: any) => params[key].includes(el));

        changed = changed || !isEqual;
      } else {
        changed = changed || !!newValue[key]?.length;
      }
    });

    return changed;
  }

  private checkIfFilterFormEmpty() {
    const isNotEmpty = Object.keys(this.filterForm.value).some(
      key => this.filterForm.get(key)?.value?.length
    );

    this._hasClearButton.next(isNotEmpty);
  }
}
