import { Component, OnInit } from '@angular/core';
import {
  CreateSupplierApiDialogData,
  EditSupplierApiDialogData,
  IListParams,
  ISupplierApiResponse,
  List,
  ListComponent,
  SupplierApi,
} from '@shabic/models';
import { AppEvent, EAppEvent, EventBrokerService } from '@shabic/event-broker';
import { switchMap } from 'rxjs/operators';
import { SupplierApiService } from '@shabic/core';

@Component({
  selector: 'shabic-supplier-api',
  templateUrl: './supplier-api.component.html',
  styleUrls: ['./supplier-api.component.css'],
})
export class SupplierApiComponent
  extends ListComponent<SupplierApi, ISupplierApiResponse[]>
  implements OnInit
{
  constructor(
    private eventBrokerService: EventBrokerService,
    private supplierApiService: SupplierApiService

  ) {
    super();
  }

  ngOnInit(): void {

    this.initList(
      switchMap(params =>
        this.supplierApiService.getAllForCurrentUser(params as IListParams)
      )
    );

    this.setParams({
      ...List.initParams,
    
    });
  }

  onChangeParam(params: IListParams): void {
    this.setParams(params);
  }

  onAddApi() {
    console.log('here we go');
    this.eventBrokerService.emit(
      new AppEvent<CreateSupplierApiDialogData>(
        EAppEvent.OpenSupplierApiDialog,
        new CreateSupplierApiDialogData(
          payload => this.supplierApiService.create(payload),
          () => this.reload()
        )
      )
    );
  }


  onEdit(api: SupplierApi) {
    this.eventBrokerService.emit(
      new AppEvent<EditSupplierApiDialogData>(
        EAppEvent.OpenSupplierApiDialog,
        new EditSupplierApiDialogData(  api,    
          payload => this.supplierApiService.update(payload,api.id),
          () => this.reload()
        )
      )
    );
  }
  onDelete(api: SupplierApi) {
    this.supplierApiService.delete(api.id).subscribe(()=>this.reload());
  }

}
