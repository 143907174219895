import { getImageExample } from '@shabic/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Address, IAddress } from './address.model';
import { Contact, IContactResponse } from './contact.model';
import { Time } from './time.model';
import { Translated } from './translated.model';
import { ApiErrorResponse, ApiResponse, isErrorResponse } from './api.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormModel } from './form.model';
import { Option } from './option.model';
import { TranslateService } from '@ngx-translate/core';
import { IListParams } from './list.model';
import { Promotion, PromotionType } from './promotion.model';
import * as R from 'ramda';

export type CompanyValue = 'types' | 'classifications' | 'businessActivities'| 'posTypes';
export type CompanyPayload = Omit<
  ICompanyResponse,
  'owner' | 'id' | 'activated'
> & { mediaId?: File[] };
export type CompanyStatus = 'PENDING' | 'CONFIRMED' | 'REJECTED';
export type CompanyDocumentStatus = 'CURRENT' | 'REJECTED' | 'READ';

export interface ICompanyResponse extends IAddress {
  crNumber: string;
  type: string;
  posType: string;
  businessActivity: string;
  classification: string;
  email: string;
  phone: string;
  id: number;
  nameFirst?: string;
  nameSecond: string;
  webSite?: string;
  mediaUrl: string | null;
  formationDate: string;
  status?: CompanyStatus;
  rejectReason?: string;
  supplier?: boolean;
  owner?: IContactResponse;
}

export interface ICompanyDocumentResponse {
  name: string;
  id: number;
  type: string;
  description: string;
  companyId: number;
  status: CompanyDocumentStatus;
  createdDate: string;
}

export interface ICompanyParams extends IListParams {
  supplier: boolean;
}

export class CompanyDocument {
  name: string;
  id: number;
  type: string;
  description: string;
  companyId: number;
  file?: unknown;
  createdDate: Time;
  status: CompanyDocumentStatus;

  constructor(document: ICompanyDocumentResponse) {
    this.name = document.name;
    this.id = document.id;
    this.type = document.type;
    this.description = document.description;
    this.companyId = document.companyId;
    this.status = document.status;
    this.createdDate = new Time(document.createdDate);
  }

  setFile(file: unknown) {
    this.file = file;

    return this;
  }
}

export class Company {
  name: Translated<string>;
  crNumber: string;
  formationDate: Time;
  type: string;
  posType: string;
  activity: string;
  classification: string;
  email: string;
  phone: string;
  webSite?: string;
  id: number;
  logo: string | null;
  owner?: Contact;
  status?: CompanyStatus;
  rejectReason?: string;
  isSupplier?: boolean;
  address: Address;

  constructor(payload: ICompanyResponse) {
    this.name = new Translated({
      en: payload.nameFirst || 'Saudi Basic Industries Corp (SABIC)',
      ar: payload.nameSecond || 'الشركة السعودية للصناعات الأساسية',
    });
    this.crNumber = payload.crNumber;
    this.formationDate = new Time(payload.formationDate);
    this.type = payload.type;
    this.posType = payload.posType;
    this.activity = payload.businessActivity;
    this.classification = payload.classification;
    this.email = payload.email;
    this.phone = payload.phone;
    this.webSite = payload.webSite;
    this.id = payload.id;
    this.logo = payload.mediaUrl || getImageExample();
    this.status = payload.status;
    this.rejectReason = payload.rejectReason;
    this.isSupplier = payload.supplier;
    this.owner = payload.owner ? new Contact(payload.owner) : undefined;
    this.address = new Address({
      city: payload.city,
      poBox: payload.poBox,
      zipCode: payload.zipCode,
      street: payload.street,
      longitude: payload.longitude,
      latitude: payload.latitude,
    });
  }

  get isPending() {
    return this.status === 'PENDING';
  }

  get isRejected() {
    return this.status === 'REJECTED';
  }

  get isConfirmed() {
    return this.status === 'CONFIRMED';
  }

  getFormPayload(): CompanyPayload {
    return {
      crNumber: this.crNumber,
      type: this.type,
      posType: this.posType,
      businessActivity: this.activity,
      classification: this.classification,
      email: this.email,
      phone: this.phone,
      nameFirst: this.name.get('en') || '',
      nameSecond: this.name.get('ar') || '',
      street: this.address.street,
      city: this.address.city,
      poBox: this.address.poBox,
      zipCode: this.address.zipCode,
      webSite: this.webSite,
      mediaId: undefined,
      mediaUrl: this.logo,
      formationDate: this.formationDate.toFormDateString(),
      latitude: this.address.latitude,
      longitude: this.address.longitude,
    };
  }
}

export interface ICompanyAPIService {
  getMyCompany: () => Observable<ApiResponse<Company | ApiErrorResponse>>;
  getCompanyDocuments: (
    company: Company
  ) => Observable<ApiResponse<CompanyDocument[] | ApiErrorResponse>>;
  getCompanyDocument: (
    document: CompanyDocument,
    download: boolean
  ) => Observable<ApiResponse<Blob | ApiErrorResponse>>;
  register: (
    payload: CompanyPayload
  ) => Observable<ApiResponse<Company | ApiErrorResponse>>;
  updateLogo: (
    payload: CompanyPayload
  ) => Observable<ApiResponse<Company | ApiErrorResponse>>;
  getOptionValue: (target: string | null, type: CompanyValue) => unknown | null;
  updateCompanyDocuments: (
    files: File[]
  ) => Observable<ApiResponse<CompanyDocument[] | ApiErrorResponse>>;
  getValues: () => Observable<
    ApiResponse<Record<CompanyValue, string[]> | ApiErrorResponse>
  >;
  deleteCompanyDocument: (
    document: CompanyDocument
  ) => Observable<ApiResponse<unknown | ApiErrorResponse>>;
}

class CompanyDocuments {
  private _documents = new BehaviorSubject<CompanyDocument[]>([]);
  private _error = new BehaviorSubject<string>('');

  documents$ = this._documents.asObservable();
  error$ = this._error.asObservable();

  formGroup = new FormGroup({
    files: new FormArray([new FormControl(null)]),
  });

  constructor(
    private company: Company,
    private companyService: ICompanyAPIService
  ) {}

  getDocument(document: CompanyDocument, download: boolean = false) {
    return this.companyService.getCompanyDocument(document, download);
  }

  updateDocuments() {
    this.companyService
      .getCompanyDocuments(this.company)
      .subscribe(response => {
        if (isErrorResponse(response)) {
          this._error.next(response.payload.message || '');
        } else if (Array.isArray(response.payload)) {
          this._documents.next(response.payload);
        }
      });
  }
}

export class CompanyModel extends FormModel<Company | CompanyDocument[]> {
  private _company?: Company;
  private _documents?: CompanyDocuments;
  private _modelError = new BehaviorSubject<string | undefined>(undefined);
  private _types = new BehaviorSubject<Option[]>([]);
  private _classifications = new BehaviorSubject<Option[]>([]);
  private _activities = new BehaviorSubject<Option[]>([]);
  private _posTypes = new BehaviorSubject<Option[]>([]);
  types$ = this._types.asObservable();
  classifications$ = this._classifications.asObservable();
  activities$ = this._activities.asObservable();
  posTypes$ = this._posTypes.asObservable();
  documents$?: Observable<CompanyDocument[]>;

  form = new FormGroup({
    company: new FormGroup({
      mediaId: new FormControl(''),
      mediaUrl: new FormControl(null),
      nameFirst: new FormControl(null),
      nameSecond: new FormControl(null, [Validators.required]),
      crNumber: new FormControl(null, [Validators.required]),
      formationDate: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      businessActivity: new FormControl(null, [Validators.required]),
      classification: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required]),
      street: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      poBox: new FormControl(null, [Validators.required]),
      zipCode: new FormControl(null, [Validators.required]),
      webSite: new FormControl(null),
      longitude: new FormControl(null),
      latitude: new FormControl(null),
    }),
    documents: new FormArray([]),
  });

  constructor(
    private api: ICompanyAPIService,
    private translateService: TranslateService
  ) {
    super();
    this.api.getValues().subscribe(response => {
      if (isErrorResponse(response)) {
        return;
      }

      const values = response.payload as Record<CompanyValue, string[]>;

      this._types.next(
        values.types.map(item => new Option(this.translateService, item))
      );
      this._classifications.next(
        values.classifications.map(
          item => new Option(this.translateService, item)
        )
      );
      this._activities.next(
        values.businessActivities.map(
          item => new Option(this.translateService, item)
        )
      );
      this._posTypes.next(
        values.posTypes.map(
          item => new Option(this.translateService, item)
        )
      );
    });
  }

  get hasCompany() {
    return !!this._company;
  }

  get isPending() {
    return this.hasCompany && this._company?.status === 'PENDING';
  }

  get isRejected() {
    return this.hasCompany && this._company?.status === 'REJECTED';
  }

  get isConfirmed() {
    return this.hasCompany && this._company?.status === 'CONFIRMED';
  }

  get companyForm() {
    return this.getForm('company') as FormGroup;
  }

  get address() {
    return this._company?.address;
  }

  getForm(name: string) {
    const control = this.form.get(name);

    return control ? (control as FormGroup) : null;
  }

  init() {
    return this.api.getMyCompany().pipe(
      map(response => {
        if (response.payload instanceof Company) {
          this._company = response.payload;
          const companyPayload = response.payload.getFormPayload();
          this.getForm('company')?.patchValue({
            ...companyPayload,
            phone: companyPayload.phone.replace('+966', ''),
          });
          this._documents = new CompanyDocuments(this._company, this.api);
          this.documents$ = this._documents.documents$;

          if (this._company.isConfirmed) {
            this.form.disable();
            this.form.get('mediaId')?.enable();
          }
        }

        return this;
      })
    );
  }

  updateDocuments() {
    this._documents?.updateDocuments();
  }

  register() {
    const companyForm = this.getForm('company') as FormGroup;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mediaUrl, ...company } = companyForm.value;
    const body = {
      ...company,
      phone: '+966' + company.phone,
      type: this.getOptionKey(this._types.value, company.type),
      classification: this.getOptionKey(
        this._classifications.value,
        company.classification
      ),
      businessActivity: this.getOptionKey(
        this._activities.value,
        company.businessActivity
      ),
      formationDate: company.formationDate
        ? new Date(company.formationDate).toISOString()
        : '',
    };

    return this.submitForm(companyForm, this.api.register(body));
  }

  updateCompany() {
    const companyForm = this.getForm('company') as FormGroup;

    return this.submitForm(companyForm, this.api.updateLogo(companyForm.value));
  }

  uploadDocuments() {
    const documentsForm = this.getForm('documents') as FormGroup;

    const formValue: File[][] = documentsForm.value;
    const files = formValue.filter(el => !!el).map(el => el[0]);

    if (!files.length) {
      return;
    }

    return this.submitForm(
      documentsForm,
      this.api.updateCompanyDocuments(files)
    );
  }

  pushDocumentControl() {
    (this.getForm('documents') as unknown as FormArray).push(
      new FormControl(null)
    );
  }

  getDocument(document: CompanyDocument, download: boolean) {
    return this.api.getCompanyDocument(document, download);
  }

  deleteDocument(document: CompanyDocument) {
    return this.api
      .deleteCompanyDocument(document)
      .pipe(tap(() => this.updateDocuments()));
  }

  getOptionKey(collection: Option[], value: string) {
    return collection.find(option => option.label === value)?.value;
  }

  updateAddress(address: Address) {
    this.companyForm.patchValue(address);
  }
}

export class CompanyPromotion {
  company: Company;
  banner?: Record<PromotionType, Promotion | undefined>;

  constructor(payload: { company: Company; promotions: Promotion[] }) {
    this.company = payload.company;
    this.banner = this.fillBanners(payload.promotions);
  }

  getBanner(type: PromotionType) {
    return this.banner?.[type];
  }

  private fillBanners(promotions: Promotion[]) {
    return R.zipObj(
      R.map(el => R.prop('type', el), promotions),
      promotions
    );
  }
}
