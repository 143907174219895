import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import {
  ApiErrorResponse,
  ApiResponse,
  IProductResponse,
  Product,
  ENV,
  IEnvironment,
  IListResponse,
  List,
  ProductSearchContent,
  ListProduct,
  ApiMarketplace,
  Quota,
  ProductStatus,
  IQuotaResponse,
  FilterOption,
  IFilterOption,
  PieChartConfig,
  PRODUCT_REPORT_SIZE,
} from '@shabic/models';
import { Observable, of } from 'rxjs';
import { map, catchError, scan, delay } from 'rxjs/operators';
import {
  CLIENT_APP,
  LIST_SIZE,
  MY_QUOTAS_STORAGE_KEY,
} from '@shabic/constants';
import { EventSourceService } from './event-source.service';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends ApiMarketplace {
  override readonly url = '/products';

  constructor(
    private http: HttpClient,
    private eventSourceService: EventSourceService,
    private storageService: StorageService,
    private translateService: TranslateService,
    @Inject(ENV) private env: IEnvironment
  ) {
    super(env);
  }

  getProducts(
    payload: Record<string, any>
  ): Observable<
    ApiResponse<List<ListProduct, ProductSearchContent> | ApiErrorResponse>
  > {
    const { size = LIST_SIZE, page, sort, ...rest } = payload;
    const query = { size, page, sort };

    if (!sort) {
      delete query.sort;
    }

    const params = new HttpParams({
      fromObject: query,
    });

    return this.http
      .post<IListResponse<ProductSearchContent>>(
        this.getEndpoint('/search'),
        rest,
        {
          params,
        }
      )
      .pipe(
        map(response => {
          return new ApiResponse(
            new List(response, content => {
              const payload = content[0];

              if (!payload) {
                return [];
              }

              if (this.env.app === CLIENT_APP) {
                return Object.keys(payload).map(
                  (id, index) =>
                    new ListProduct(
                      {
                        ...payload[id][0],
                        isTopSelection: index < 5,
                      },
                      payload[id]
                    )
                );
              } else {
                const result: ListProduct[] = [];

                Object.keys(payload).forEach(el => {
                  result.push(
                    ...payload[el].map(item => new ListProduct(item))
                  );
                });
                return result;
              }
            })
          );
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }
  getProductsCount(
  ): Observable<number> {
    

  
    return this.http
      .post<IListResponse<ProductSearchContent>>(
        this.getEndpoint('/search?size=12&page=0&sort=lastModifiedDate,asc')
        ,
        {
          "skipMyProducts":false,
          "statuses":["ACTIVE"],
          "attributeFilter":{}
 
        }
      )
      .pipe(
        map(response => {
          // Extract and return the total number of elements
          return response.totalElements;
        }),
        catchError(response =>
          of(0) // Return 0 in case of an error
        )
      );
  }

  getProduct(id: string) {
    return this.getProducts({
      page: 0,
      uuids: [id],
      statuses: ['ACTIVE'],
      attributeFilter: {},
      skipMyProducts: true,
    }).pipe(
      map(response => {
        if (response.payload instanceof List) {
          return response.payload.content[0];
        }

        return null;
      })
    );
  }

  getQuotas(product: ListProduct, quantity: number = 1) {
    const body = {
      items: [
        {
          companyIds: product.suppliers.map(el => el.supplier?.id),
          barCode: product.barCode,
          quantity,
        },
      ],
    };

    return this.eventSourceService
      .getEventSource(this.getEndpoint('/quotas'), body)
      .pipe(
        scan((acc, curr) => {
          if ('data' in curr) {
            const quotas = JSON.parse(curr.data);
            quotas.items.forEach((el: any) => {
              el['companyId'] = quotas.companyId;
            });

            acc.push(...quotas.items);
          }

          return acc;
        }, <any[]>[]),
        map(response => {
          return new ApiResponse(
            response.map(el => {
              const currentProduct = product.suppliers.find(
                item => item.supplier?.id === el.companyId
              );

              return new Quota(el, currentProduct || product);
            })
          );
        })
      );
  }
  getQuotasForList(products: ListProduct[], quantities: number[]) {
    
    const body = {
      items: products.map((p, index) => ({
      companyIds: p.suppliers.map(el => el.supplier?.id),
      barCode: p.barCode,
      quantity: quantities[index]
      })),
    };

    return this.eventSourceService
      .getEventSource(this.getEndpoint('/quotas'), body)
      .pipe(
        scan((acc, curr) => {
          if ('data' in curr) {
            const quotas = JSON.parse(curr.data);
            quotas.items.forEach((el: any) => {
              el['companyId'] = quotas.companyId;
            });

            acc.push(...quotas.items);
          }

            return acc;
          }, <any[]>[]),
          map(response => {
            return new ApiResponse(
            response.map((el, index) => {
            const currentProduct = products.find(
            item => item.barCode === el.barCode
            );
                          
              return new Quota(el, currentProduct || products[index]);
            })
            );
        })
      );
  }

  getMyQuotas() {
    const quotas: Array<{
      quota: IQuotaResponse;
      product: IProductResponse;
      quantity: number;
    }> =
      this.storageService.retrieve<
        Array<{
          quota: IQuotaResponse;
          product: IProductResponse;
          quantity: number;
        }>
      >(MY_QUOTAS_STORAGE_KEY, true) || [];

    return of(
      new List<Quota, Quota[]>(
        {
          content: quotas.map(
            el => new Quota(el.quota, new Product(el.product), el.quantity)
          ), // storage value
          number: 0,
          size: 10,
          totalElements: quotas.length,
          totalPages: 1,
          last: true,
        },
        content => content
      )
    ).pipe(delay(1000));
  }

  changeStatus(product: Product, status: ProductStatus, reason?: string) {
    return this.http
      .put<unknown>(this.getEndpoint(`/${product.id}/status`), {
        status,
        reason: reason || '',
      })
      .pipe(
        map(response => new ApiResponse(response)),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getFilterTypes() {
    return this.http.get<string[]>(this.getEndpoint('/filters/type')).pipe(
      map(response => new ApiResponse(response)),
      catchError(response =>
        of(new ApiResponse(new ApiErrorResponse(response)))
      )
    );
  }

  getFilterTypeValues(
    type: string[] | string,
    params: Record<string, string[]>
  ) {
    return this.http
      .post<Record<string, IFilterOption[]>>(
        this.getEndpoint('/filters/search'),
        { types: Array.isArray(type) ? type : [type] }
      )
      .pipe(
        map(response => {
          const options: Record<string, FilterOption[]> = {};
          Object.keys(response).forEach(key => {
            options[key] = response[key].map(el => {
              const selectedValues = params[key] || [];

              return new FilterOption(
                el,
                selectedValues.includes(el.name) ||
                  selectedValues.includes(el.id)
              );
            });
          });

          return new ApiResponse(options);
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getProductReport(type: 'COMPANY' | 'CATEGORY' | 'BRAND') {
    const params = new HttpParams({
      fromObject: { type, size: 1000 },
    });

    return this.http
      .get<{ name: string; count: number }[]>(
        this.getApiEndpoint(`/reports/products`),
        { params }
      )
      .pipe(
        map(response => {
          response.sort((prev, next) => next.count - prev.count);

          const others = response
            .slice(PRODUCT_REPORT_SIZE)
            .reduce((acc, curr) => curr.count + acc, 0);
          const labels = response
            .slice(0, PRODUCT_REPORT_SIZE)
            .map(el => el.name);
          const values = response
            .slice(0, PRODUCT_REPORT_SIZE)
            .map(el => el.count);

          return new ApiResponse({
            label: 'common.product-report',
            config: new PieChartConfig(
              [...values, others],
              [...labels, this.translateService.instant('common.others')]
            ),
          });
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }

  getProductCountByCategory() {
    const params = new HttpParams({
      fromObject: { type: 'CATEGORY', size: 1000 },
    });

    return this.http
      .get<{ count: number; id: number }[]>(
        this.getApiEndpoint(`/reports/products`),
        { params }
      )
      .pipe(
        map(response => {
          const categories: Record<number, number> = {};

          response.forEach(el => {
            categories[el.id] = el.count;
          });

          return new ApiResponse(categories);
        }),
        catchError(response =>
          of(new ApiResponse(new ApiErrorResponse(response)))
        )
      );
  }
}
