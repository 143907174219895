import { getCompanyProductsQuery } from '@shabic/constants';
import { CompanyAddress } from './address.model';
import { Company } from './company.model';
import { Translated } from './translated.model';

export class Supplier {
  id: number;
  logo: string | null;
  name: Translated<string>;
  type: string;
  classification: string;
  activity: string;
  website?: string;
  phone: string;
  address: CompanyAddress;
  productsQuery: string;
  email: string;

  constructor(company: Company) {
    this.id = company.id;
    this.logo = company.logo;
    this.name = company.name;
    this.type = company.type;
    this.classification = company.classification;
    this.activity = company.activity;
    this.website = company.webSite;
    this.phone = company.phone;
    this.address = new CompanyAddress({
      city: company.address.city,
      poBox: company.address.poBox,
      street: company.address.street,
      zipCode: company.address.zipCode,
    });
    this.productsQuery = getCompanyProductsQuery(company.id);
    this.email = company.email;
  }
}


export interface ISupplierApiResponse {
  id: number;
  apiType: string;
  environment: string;
  endPointURL: string;
  swaggerURL: string;
  authenticationType: string;
  userName: string;
  password: string;
  apiKey: string;  

 



}

export class SupplierApi {
  id: number;
  apiType: string;
  environment: string;
  endPointURL: string;
  swaggerURL: string;
  authenticationType: string;
  userName: string;
  password: string;
  apiKey: string;  

  constructor (payload: ISupplierApiResponse) {

    this.id = payload.id;
    this.apiType = payload.apiType;
    this.environment = payload.environment;
    this.endPointURL = payload.endPointURL;
    this.swaggerURL = payload.swaggerURL;
    this.authenticationType = payload.authenticationType;
    this.userName = payload.userName;
    this.password = payload.password;
    this.apiKey = payload.apiKey;
  }



}
