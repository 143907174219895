import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDropdownComponent } from './components';
import { UiModule } from '@shabic/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlModule } from '@shabic/form-control';
import { IconModule } from '@shabic/icon';

@NgModule({
  declarations: [FilterDropdownComponent],
  imports: [
    CommonModule,
    UiModule,
    TranslateModule,
    FormControlModule,
    IconModule,
  ],
  exports: [FilterDropdownComponent],
})
export class FiltersModule {}
