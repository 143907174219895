import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ListProduct, RequestProductsDialogData } from '@shabic/models';
import { DialogComponent } from '../dialog/dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ProductDataService } from '../../services';

@Component({
  selector: 'shabic-request-products-dialog',
  templateUrl: './request-products-dialog.component.html',
  styleUrls: ['./request-products-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestProductsDialogComponent {
  readonly products: ListProduct[];

  formGroup: FormGroup = this.fb.group({
    quantities: this.fb.array([])
  });
  constructor(@Inject(DIALOG_DATA) public data: RequestProductsDialogData,
    private route: ActivatedRoute,
    private router: Router,
    private productDataService: ProductDataService,
    private fb: FormBuilder,


  ) {
    this.products = data.products;
  }
  getQuantity(): number {
    return Number(this.formGroup.get('quantity')?.value) || 0;
  }
  ngOnInit(): void {
    this.formGroup.setControl('quantities', this.fb.array(this.products.map(() => this.createQuantityFormControl())));

  }

  createQuantityFormControl(): FormGroup {
    return this.fb.group({
      quantity: [1, [Validators.required, Validators.min(1)]]
    });
  } get quantitiesFormArray(): FormArray {
    return this.formGroup.get('quantities') as FormArray;
  }


  confirm(dialog: DialogComponent) {
    this.data.onConfirm(this.products).subscribe((products) => {
      const updatedQuantities = this.quantitiesFormArray.value;
      const quantitiesList: number[] = updatedQuantities.map((item: { quantity: any }) => Number(item.quantity));
      this.router.navigate(['/catalog/all/']);
      this.productDataService.setProducts(this.products);
      this.productDataService.setQuantities(quantitiesList);
      this.productDataService.updateProductQuantityMap();
      dialog.close();

      this.router.navigate(['/catalog/all']);

    });


  }

}
