import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { numberValidator } from '@shabic/constants';
import { CurrencyService } from '@shabic/core';
import { ListProduct } from '@shabic/models';

@Component({
  selector: 'shabic-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsComponent implements OnInit {
  @Input() product!: ListProduct;
  @Input() requestedAmount?: number | null;
  @Input() isBulk?: boolean;

  @Output() requested = new EventEmitter<number>();

  form: FormGroup = new FormGroup({
    amount: new FormControl('', [Validators.required, numberValidator()]),
  });

  currentCurrency$ = this.currencyService.currentCurrency$;

  constructor(private currencyService: CurrencyService) {}

  ngOnInit(): void {
    if (this.requestedAmount) {
      this.form.patchValue({ amount: this.requestedAmount });
    }
  }

  requestProduct(): void {
    this.requested.emit(this.form.value.amount);
  }
}
