<div class="relative grid gap-4 desktop:gap-5">
  <div class="grid gap-1">
    <shabic-headline variant="h4">
      <a [routerLink]="!isBulk ? ['..', product.id] : null" class="font-semibold">
        {{ product.name | currentLang | async }}
      </a>
    </shabic-headline>

    <span class="desktop:hidden text-sm font-semibold text-grey">
      {{ product.brand }}
    </span>

    <div class="flex items-center gap-2">
      <span shabicBadge="secondary">
        {{ product.category?.name | currentLang | async }}
      </span>

      <span
        class="hidden desktop:inline-block w-0.5 h-0.5 bg-grey rounded-full"
      ></span>

      <span class="hidden desktop:inline text-sm font-semibold text-grey">
        {{ product.brand }}
      </span>
    </div>
  </div>

  <div *ngIf="!requestedAmount" class="flex gap-1 items-end">
    <strong class="text-2xl font-medium text-secondary">
      {{ product.priceFirst | convert: product.currency | async }}
    </strong>

    <span class="text-xs text-grey font-medium -translate-y-0.5">
      ({{
        'common.unit-price'
          | translate: { currency: (currentCurrency$ | async)?.value }
      }})
    </span>
  </div>

  <form [formGroup]="form" (ngSubmit)="requestProduct()">
    <shabic-form-field-label>
      {{ 'common.quantity' | translate }}
    </shabic-form-field-label>

    <div
      class="inline-flex items-center gap-3 p-1 border rounded-2xl border-grey-350 bg-white w-full desktop:w-auto"
    >
      <span class="ltr:ml-[10px] rtl:mr-[10px]">
        <ng-template shabicIcon="icon-box"></ng-template>
      </span>

    <shabic-form-field-label class="border-0 mt-1" *ngIf="isBulk">
      {{ requestedAmount }}
    </shabic-form-field-label>
      <shabic-form-field [hideErrors]="true">
        <input
        *ngIf="!isBulk"
          type="number"
          formControlName="amount"
          min="1"
          shabicInput
          name=""
          placeholder="1"
          class="border-0 p-0 h-5.5"
          [disabled]="isBulk!"
        />
      </shabic-form-field>

      <button
        *ngIf="!isBulk"
        type="submit"
        class="px-3 shrink-0 ltr:ml-auto rtl:mr-auto"
        shabicButton="primary"
        [disabled]="form.invalid"
      >
        {{
          (requestedAmount
            ? 'button.change-request'
            : 'button.request-for-quotation'
          ) | translate
        }}
      </button>
    </div>
  </form>
</div>

<strong
  *ngIf="requestedAmount"
  class="bg-yellow rounded-2xl text-sm text-center text-primary font-semibold px-4 py-3 self-start mt-1 desktop:mt-0 self-stretch desktop:self-start"
>
  {{ product.priceFirst | convert: product.currency | async }}

  ({{
    'common.unit-price'
      | translate: { currency: (currentCurrency$ | async)?.value }
  }})
</strong>

<!-- <span
      *ngIf="!this.requestedAmount"
      class="text-xs text-grey font-medium mt-2 inline-block"
    >
      {{ 'status.items-available' | translate: { amount: product.maxCount } }}
    </span> -->
