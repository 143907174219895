import { getImageExample } from '@shabic/constants';
import { Company } from '@shabic/models';

export const CompanyPayload = {
  businessActivity: '',
  street: 'Golden Avenue, 24',
  classification: '',
  crNumber: '01234567890',
  email: 'saudi@gmail.com',
  phone: '+966 12-345-6789',
  type: 'Enterprise',
  posType: 'NO_POS',
  formationDate: new Date().toISOString(),
  city: 'London',
  id: 1,
  mediaUrl: getImageExample(),
  nameFirst: '',
  nameSecond: '',
  poBox: '',
  webSite: '',
  zipCode: '',
  activated: true,
};

export const getCompanyExample = () => new Company(CompanyPayload);
