<ng-container *ngIf="list$ | async as list">
  <shabic-search
    (search)="changeParam('search', $event)"
    (filter)="onFilterSearch()"
    (changePage)="changeParam('page', $event)"
    (loadMore)="onLoadMore()"
    [hasPagination]="hasPagination$ | async"
    [hasLoadMore]="hasLoadMore$ | async"
    [query]="(params$ | async)?.['search']"
    [page]="(params$ | async)?.page"
    [pages]="list.pages"
    [total]="list.total"
    [loadMoreLoading]="loadMoreloading$ | async"
  >
    <span *ngIf="!selectedCategory" title>{{ name | translate }}</span>

    <span *ngIf="selectedCategory" title>
      {{ selectedCategory.name | currentLang | async }}
    </span>

    <ng-container aside>
      <ng-container *ngIf="categories$ | async as categories">
        <shabic-search-filter-categories
          class="hidden desktop:block"
          *ngIf="categories.length"
          [categories]="categories"
          (changed)="onChangeCategory($event)"
        ></shabic-search-filter-categories>

        <hr *ngIf="categories.length" shabicSearchFilterDivider />
      </ng-container>

      <form
        class="relative grid gap-3"
        [formGroup]="filterForm"
        (ngSubmit)="onFilterSearch()"
      >
        <button
          *ngIf="hasClearButton$ | async"
          type="reset"
          class="text-red inline-flex justify-center items-center gap-[10px] p-2 rounded-2xl bg-red/[0.1] hover:bg-red/[0.2] transition-colors"
          (click)="onReset()"
        >
          <ng-template shabicIcon="close"></ng-template>

          {{ 'button.clear-filters' | translate }}
        </button>

        <shabic-search-filter
          *ngIf="suppliers$ | async as suppliers; else loader"
          name="common.supplier"
          param="COMPANY"
          property="value"
          [options]="suppliers"
        ></shabic-search-filter>

        <hr shabicSearchFilterDivider />

        <shabic-search-filter
          *ngIf="brands$ | async as brands; else loader"
          name="common.brand"
          param="BRAND"
          [options]="brands"
        ></shabic-search-filter>

        <hr shabicSearchFilterDivider />

        <shabic-search-filter
          *ngIf="countries$ | async as countries; else loader"
          name="common.country-of-origin"
          param="COUNTRY"
          [options]="countries"
        ></shabic-search-filter>

        <hr shabicSearchFilterDivider />

        <shabic-search-filter
          *ngIf="colors$ | async as colors; else loader"
          name="common.color"
          param="COLOR"
          [options]="colors"
        ></shabic-search-filter>

        <!-- <ng-container *ngIf="filterChanged$ | async">
          <button class="w-full" shabicButton="primary" type="submit">
            {{ 'button.search' | translate }}
          </button>
        </ng-container> -->
      </form>
    </ng-container>

    <ng-container content>
      <div class="grid grid-flow-row gap-3 desktop:flex mb-3 desktop:mb-5">
        <div class="flex flex-col gap-5 desktop:grow">
          <ng-container *ngIf="tags$ | async as tags">
            <shabic-search-filter-tags
              *ngIf="tags.length"
              [tags]="tags"
              (changed)="onChangeTag($event)"
            ></shabic-search-filter-tags>
          </ng-container>

          <shabic-product-statuses
            *ngIf="hasProductStatusFilter"
            (changed)="onStatusChange($event)"
            [statuses]="statuses"
          ></shabic-product-statuses>
        </div>

        <div class="grid grid-flow-col auto-cols-fr gap-2">
          <shabic-filter-dropdown
            label="common.sort-by"
            [options]="sortOptions"
            [value]="(params$ | async)?.['sort']"
            (sort)="onSort($event)"
          ></shabic-filter-dropdown>

          <button
            class="text-sm text-primary font-semibold rounded-2xl bg-grey-350 desktop:hidden"
            type="button"
            (click)="onShowFilter()"
          >
            {{ 'common.filter' | translate }}
          </button>
        </div>
      </div>

      <ng-container *ngIf="loading$ | async; else listContent">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </ng-container>

      <ng-template #listContent let-content="content">
        <div class="grid desktop:grid-cols-cards gap-4">
          <div
            [formGroup]="listForm"
            class="relative flex flex-col gap-1 border border-grey-350 rounded-3xl p-[20px] items-center animate__animated bg-white"
            shabicInViewport
            inViewportClass="animate__fadeInUp"
            *ngFor="let product of list.content"
          >
            <div class="absolute top-[20px] left-[20px] z-1">
              <shabic-form-field>
                <shabic-checkbox-control>
                  <input
                    type="checkbox"
                    name=""
                    hidden
                    class="peer"
                    [formControlName]="product.barCodeUuid"
                    shabicInput
                  />
                </shabic-checkbox-control>
              </shabic-form-field>
            </div>

            <div
              class="flex flex-col items-end gap-1 absolute top-[20px] right-[20px]"
            >
              <div
                *ngIf="product.isTopSelection"
                class="uppercase text-primary font-semibold text-[10px] inline-flex items-center gap-1 rounded-3xl bg-yellow px-2 py-1"
              >
                <ng-template shabicIcon="icon-star" size="xs"></ng-template>

                {{ 'common.top-selection' | translate }}
              </div>

              <span
                *ngIf="product.country && product.country.isSaudi"
                class="inline-flex items-center gap-1 text-primary font-medium text-[12px] rounded-3xl bg-white px-2 py-1 border border-grey-350"
              >
                <span>{{ product.country.flag }}</span>

                {{
                  'common.made-in' | translate: { name: product.country.name }
                }}
              </span>
            </div>

            <div class="flex flex-col items-center gap-1">
              <img
                [src]="product.images[0]"
                shabicImage
                width="200"
                height="150"
                class="h-[150px] w-[200px] border-0"
              />

              <shabic-headline variant="h4" align="center">
                <span class="font-semibold">
                  {{ product.name | currentLang | async }}
                </span>
              </shabic-headline>

              <p class="text-xs font-semibold text-grey text-center -mt-0.5">
                {{ product.brand }}
              </p>
            </div>

            <span shabicBadge="secondary">
              {{ product.category?.name | currentLang | async }}
            </span>

            <ng-container [ngSwitch]="app">
              <shabic-product-admin-actions
                [product]="product"
                *ngSwitchCase="'admin'"
              ></shabic-product-admin-actions>

              <shabic-product-client-actions
                [product]="product"
                (request)="onRequest(product)"
                *ngSwitchCase="'client'"
              ></shabic-product-client-actions>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <!-- <ng-container startAction>
      <button
        *ngIf="app === 'admin'"
        type="button"
        shabicButton="danger"
        class="invisible"
      >
        {{ 'button.deactivate-products' | translate }}
      </button>

      <button
        *ngIf="app === 'client'"
        type="button"
        shabicButton="primary"
        class="invisible"
      >
        {{ 'button.request-for-quotation-for-selected' | translate }}
      </button>
    </ng-container>

    <ng-container endAction>
      <button *ngIf="app === 'admin'" type="button" shabicButton="danger">
        {{ 'button.deactivate-products' | translate }}
      </button>

      <button
        *ngIf="app === 'client'"
        type="button"
        shabicButton="primary"
        [disabled]="(hasSelectedProducts$ | async) === false"
        (click)="onRequestSelectedProducts()"
      >
        {{ 'button.request-for-quotation-for-selected' | translate }}
      </button>
    </ng-container> -->
  </shabic-search>
</ng-container>

<ng-template #loader>
  <div class="text-grey flex justify-center py-5">
    <shabic-spinner size="xl"></shabic-spinner>
  </div>
</ng-template>
